<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <div>
            <!-- <h3 class="form-subtitle mb__12">{{ $t('Additional information') }}</h3> -->
            <FieldGroup_input
                    :field-group_class="'field-group_width_200 mb__12'"
                    :id="'max-permissible-speed'"
                    :label="$t('Unit.Max permissible speed')+', '+speedUnits"
                    :error="errors.max_speed"
                    v-model="unit.max_speed"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'plate-number'"
                :label="$t('Unit.Plate number')"
                :error="errors.platenumber"
                v-model="unit.platenumber"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'make'"
                :label="$t('Unit.Make')"
                :error="errors.mark"
                v-model="unit.mark"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'model'"
                :label="$t('Unit.Model')"
                :error="errors.model"
                v-model="unit.model"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'year'"
                :label="$t('Unit.Year')"
                :error="errors.year"
                v-model="unit.year"
                v-if="false"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'vin'"
                :label="$t('Unit.VIN')"
                :error="errors.vin"
                v-model="unit.vin"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'color'"
                :label="$t('Unit.Color')"
                :error="errors.unit_color"
                v-model="unit.unit_color"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'mileage'"
                :label="$t('Unit.Mileage')"
                :error="errors.mileage"
                v-model="unit.mileage"
            />
        </div>
    </section>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'UnitEdit_AddInfo',
        props: [
            'value',
            'unitId',
            'errors',
        ],
        components: {},
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "getAppUserSettings",
                "getAppUserUnits",
                "unitsByIds",
            ]),
            isNew(){
               return !(this.unitId && this.unitId>0)
            },
            _errors(){//hack on update
                return Object.keys(this.errors)
            },
            unit(){
                return this.value
            },
            speedUnits(){
                return this.getAppUserUnits.speed
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
        created() {
            // console.log('UnitEdit_Options created', this.unitId, {...this.unit})
        },
        updated() {
            // console.log('UnitEdit_Options updated', this.unitId, {...this.unit})
        },
        mounted() {
            // console.log('UnitEdit_Options mounted', this.unitId, {...this.unit})
        }
    }
</script>

<style scoped>

</style>